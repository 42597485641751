/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type UserState = typeof UserState[keyof typeof UserState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserState = {
  InvitationPending: 'InvitationPending',
  InvitationExpired: 'InvitationExpired',
  Activated: 'Activated',
  Deactivated: 'Deactivated',
  Locked: 'Locked',
  Deleted: 'Deleted',
} as const;
