import { useMediaQuery } from '@mantine/hooks';
import { BaseLayout as ExternalBaseLayout, Notifications } from '@uag/react-core';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { useGetInboxToken } from 'api/notifications/inbox/inbox';
import { BreadcrumbContext } from './BreadcrumbContext';
import { Breadcrumbs } from './Breadcrumbs';

const knockConfiguration = {
    apiKey: import.meta.env.VITE_KNOCK_PUBLIC_API_KEY,
    feedId: import.meta.env.VITE_KNOCK_FEED_CHANNEL_ID
};

export const BaseLayout = (props: React.ComponentProps<typeof ExternalBaseLayout>) => {
    const { children, ...rest } = props;
    const isMobile = useMediaQuery('(max-width: 768px)');
    const { data: inboxToken, isLoading: isInboxTokenLoading } = useGetInboxToken({
        query: { enabled: !!import.meta.env.VITE_APP_NOTIFICATIONS_API_URL }
    });

    const baseBreadcrumbRoute: BreadcrumbsRoute = {
        path: '/',
        breadcrumb: ''
    };
    const breadcrumbRoutes = useContext(BreadcrumbContext);
    const breadcrumbs = useBreadcrumbs([baseBreadcrumbRoute, ...breadcrumbRoutes]);
    const navigate = useNavigate();

    const handleLogoCLick = () => {
        navigate('/', { state: { preventRedirect: true } });
    };

    return (
        <ExternalBaseLayout versionNumber={APP_VERSION} onLogoClick={handleLogoCLick} {...rest} background="gray" innerClassName="h-min">
            {breadcrumbs && breadcrumbs.length > 1 && <Breadcrumbs breadcrumbs={breadcrumbs} maxElements={isMobile ? 2 : undefined} />}
            <Notifications
                isLoading={isInboxTokenLoading}
                userId={inboxToken?.data?.userIdentifier}
                userToken={inboxToken?.data?.token}
                {...knockConfiguration}
            />
            {children}
        </ExternalBaseLayout>
    );
};
