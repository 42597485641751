/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateApiResourceScopeModel,
  CreateOrUpdateApiResourceModel,
  CreateOrUpdateApiResourceRoleModel,
  GetApiResourceByIdParams,
  GetApiResourceByIdResponse,
  GetApiResourceDetailsByIdParams,
  GetApiResourceDetailsByIdResponse,
  GetApiResourceRoleByIdResponse,
  GetApiResourceRoleDetailsByIdParams,
  GetApiResourceRoleDetailsByIdResponse,
  GetApiResourceRoleMembersResponse,
  GetApiResourceRolesByApiResourceIdResponse,
  GetApiResourceRolesParams,
  GetApiResourceScopeByIdResponse,
  GetApiResourceScopeClientsResponse,
  GetApiResourceScopesByApiResourceIdResponse,
  GetApiResourceScopesParams,
  GetApiResourcesParams,
  GetApiResourcesResponse,
  GetTokenExchangeClientsByApiResourceIdResponse,
  GetTokenExchangeClientsParams,
  ProblemDetails,
  UpdateApiResourceScopeModel
} from '.././models'
import { customMutator } from '../../customMutator-v3';
import type { ErrorType } from '../../customMutator-v3';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get all token exchange clients for an API Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read<br>login.umdaschgroup.com/clients.read
 */
export const getTokenExchangeClients = (
    id: string,
    params?: GetTokenExchangeClientsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTokenExchangeClientsByApiResourceIdResponse>(
      {url: `/api-resources/${id}/token-exchange`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokenExchangeClientsQueryKey = (id: string,
    params?: GetTokenExchangeClientsParams,) => {
    return [`/api-resources/${id}/token-exchange`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokenExchangeClientsQueryOptions = <TData = Awaited<ReturnType<typeof getTokenExchangeClients>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetTokenExchangeClientsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokenExchangeClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokenExchangeClientsQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokenExchangeClients>>> = ({ signal }) => getTokenExchangeClients(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokenExchangeClients>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokenExchangeClientsQueryResult = NonNullable<Awaited<ReturnType<typeof getTokenExchangeClients>>>
export type GetTokenExchangeClientsQueryError = ErrorType<ProblemDetails>


export function useGetTokenExchangeClients<TData = Awaited<ReturnType<typeof getTokenExchangeClients>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetTokenExchangeClientsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokenExchangeClients>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTokenExchangeClients>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTokenExchangeClients<TData = Awaited<ReturnType<typeof getTokenExchangeClients>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetTokenExchangeClientsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokenExchangeClients>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTokenExchangeClients>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTokenExchangeClients<TData = Awaited<ReturnType<typeof getTokenExchangeClients>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetTokenExchangeClientsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokenExchangeClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetTokenExchangeClients<TData = Awaited<ReturnType<typeof getTokenExchangeClients>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetTokenExchangeClientsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokenExchangeClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTokenExchangeClientsQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get all API Resources<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResources = (
    params?: GetApiResourcesParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourcesResponse>(
      {url: `/api-resources`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiResourcesQueryKey = (params?: GetApiResourcesParams,) => {
    return [`/api-resources`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiResourcesQueryOptions = <TData = Awaited<ReturnType<typeof getApiResources>>, TError = ErrorType<ProblemDetails>>(params?: GetApiResourcesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResources>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourcesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResources>>> = ({ signal }) => getApiResources(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResources>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourcesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResources>>>
export type GetApiResourcesQueryError = ErrorType<ProblemDetails>


export function useGetApiResources<TData = Awaited<ReturnType<typeof getApiResources>>, TError = ErrorType<ProblemDetails>>(
 params: undefined |  GetApiResourcesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResources>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResources>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResources<TData = Awaited<ReturnType<typeof getApiResources>>, TError = ErrorType<ProblemDetails>>(
 params?: GetApiResourcesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResources>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResources>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResources<TData = Awaited<ReturnType<typeof getApiResources>>, TError = ErrorType<ProblemDetails>>(
 params?: GetApiResourcesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResources>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResources<TData = Awaited<ReturnType<typeof getApiResources>>, TError = ErrorType<ProblemDetails>>(
 params?: GetApiResourcesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResources>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourcesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new API Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write
 */
export const createApiResource = (
    createOrUpdateApiResourceModel: CreateOrUpdateApiResourceModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetApiResourceByIdResponse>(
      {url: `/api-resources`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateApiResourceModel
    },
      options);
    }
  


export const getCreateApiResourceMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApiResource>>, TError,{data: CreateOrUpdateApiResourceModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createApiResource>>, TError,{data: CreateOrUpdateApiResourceModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createApiResource>>, {data: CreateOrUpdateApiResourceModel}> = (props) => {
          const {data} = props ?? {};

          return  createApiResource(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateApiResourceMutationResult = NonNullable<Awaited<ReturnType<typeof createApiResource>>>
    export type CreateApiResourceMutationBody = CreateOrUpdateApiResourceModel
    export type CreateApiResourceMutationError = ErrorType<ProblemDetails>

    export const useCreateApiResource = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApiResource>>, TError,{data: CreateOrUpdateApiResourceModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createApiResource>>,
        TError,
        {data: CreateOrUpdateApiResourceModel},
        TContext
      > => {

      const mutationOptions = getCreateApiResourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the basic information of a API Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourceById = (
    id: string,
    params?: GetApiResourceByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourceByIdResponse>(
      {url: `/api-resources/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiResourceByIdQueryKey = (id: string,
    params?: GetApiResourceByIdParams,) => {
    return [`/api-resources/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiResourceByIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetApiResourceByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceById>>> = ({ signal }) => getApiResourceById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceById>>>
export type GetApiResourceByIdQueryError = ErrorType<ProblemDetails>


export function useGetApiResourceById<TData = Awaited<ReturnType<typeof getApiResourceById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetApiResourceByIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceById<TData = Awaited<ReturnType<typeof getApiResourceById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceById<TData = Awaited<ReturnType<typeof getApiResourceById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourceById<TData = Awaited<ReturnType<typeof getApiResourceById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourceByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates an existing API Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write
 */
export const updateApiResource = (
    id: string,
    createOrUpdateApiResourceModel: CreateOrUpdateApiResourceModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetApiResourceByIdResponse>(
      {url: `/api-resources/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateApiResourceModel
    },
      options);
    }
  


export const getUpdateApiResourceMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateApiResource>>, TError,{id: string;data: CreateOrUpdateApiResourceModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateApiResource>>, TError,{id: string;data: CreateOrUpdateApiResourceModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateApiResource>>, {id: string;data: CreateOrUpdateApiResourceModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateApiResource(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateApiResourceMutationResult = NonNullable<Awaited<ReturnType<typeof updateApiResource>>>
    export type UpdateApiResourceMutationBody = CreateOrUpdateApiResourceModel
    export type UpdateApiResourceMutationError = ErrorType<ProblemDetails>

    export const useUpdateApiResource = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateApiResource>>, TError,{id: string;data: CreateOrUpdateApiResourceModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateApiResource>>,
        TError,
        {id: string;data: CreateOrUpdateApiResourceModel},
        TContext
      > => {

      const mutationOptions = getUpdateApiResourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deletes an existing API Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write
 */
export const deleteApiResource = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/api-resources/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiResourceMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiResource>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiResource>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiResource>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiResource(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiResourceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiResource>>>
    
    export type DeleteApiResourceMutationError = ErrorType<ProblemDetails>

    export const useDeleteApiResource = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiResource>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiResource>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiResourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the details of a API Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourceDetailsById = (
    id: string,
    params?: GetApiResourceDetailsByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourceDetailsByIdResponse>(
      {url: `/api-resources/${id}/detail`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiResourceDetailsByIdQueryKey = (id: string,
    params?: GetApiResourceDetailsByIdParams,) => {
    return [`/api-resources/${id}/detail`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiResourceDetailsByIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetApiResourceDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceDetailsByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceDetailsById>>> = ({ signal }) => getApiResourceDetailsById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceDetailsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceDetailsById>>>
export type GetApiResourceDetailsByIdQueryError = ErrorType<ProblemDetails>


export function useGetApiResourceDetailsById<TData = Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetApiResourceDetailsByIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceDetailsById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceDetailsById<TData = Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceDetailsById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceDetailsById<TData = Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourceDetailsById<TData = Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourceDetailsByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Moves an API Resource to another application<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write<br>login.umdaschgroup.com/applications.write
 */
export const moveApiResource = (
    id: string,
    applicationId: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/api-resources/${id}/move/${applicationId}`, method: 'POST'
    },
      options);
    }
  


export const getMoveApiResourceMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveApiResource>>, TError,{id: string;applicationId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof moveApiResource>>, TError,{id: string;applicationId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveApiResource>>, {id: string;applicationId: string}> = (props) => {
          const {id,applicationId} = props ?? {};

          return  moveApiResource(id,applicationId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MoveApiResourceMutationResult = NonNullable<Awaited<ReturnType<typeof moveApiResource>>>
    
    export type MoveApiResourceMutationError = ErrorType<ProblemDetails>

    export const useMoveApiResource = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveApiResource>>, TError,{id: string;applicationId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof moveApiResource>>,
        TError,
        {id: string;applicationId: string},
        TContext
      > => {

      const mutationOptions = getMoveApiResourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get all api resource roles for an api resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourceRoles = (
    id: string,
    params?: GetApiResourceRolesParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourceRolesByApiResourceIdResponse>(
      {url: `/api-resources/${id}/roles`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiResourceRolesQueryKey = (id: string,
    params?: GetApiResourceRolesParams,) => {
    return [`/api-resources/${id}/roles`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiResourceRolesQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceRoles>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetApiResourceRolesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceRolesQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceRoles>>> = ({ signal }) => getApiResourceRoles(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceRoles>>>
export type GetApiResourceRolesQueryError = ErrorType<ProblemDetails>


export function useGetApiResourceRoles<TData = Awaited<ReturnType<typeof getApiResourceRoles>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetApiResourceRolesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoles>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceRoles<TData = Awaited<ReturnType<typeof getApiResourceRoles>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceRolesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoles>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceRoles<TData = Awaited<ReturnType<typeof getApiResourceRoles>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceRolesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourceRoles<TData = Awaited<ReturnType<typeof getApiResourceRoles>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceRolesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourceRolesQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new api resource role<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write
 */
export const createApiResourceRole = (
    id: string,
    createOrUpdateApiResourceRoleModel: CreateOrUpdateApiResourceRoleModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetApiResourceRoleByIdResponse>(
      {url: `/api-resources/${id}/roles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateApiResourceRoleModel
    },
      options);
    }
  


export const getCreateApiResourceRoleMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApiResourceRole>>, TError,{id: string;data: CreateOrUpdateApiResourceRoleModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createApiResourceRole>>, TError,{id: string;data: CreateOrUpdateApiResourceRoleModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createApiResourceRole>>, {id: string;data: CreateOrUpdateApiResourceRoleModel}> = (props) => {
          const {id,data} = props ?? {};

          return  createApiResourceRole(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateApiResourceRoleMutationResult = NonNullable<Awaited<ReturnType<typeof createApiResourceRole>>>
    export type CreateApiResourceRoleMutationBody = CreateOrUpdateApiResourceRoleModel
    export type CreateApiResourceRoleMutationError = ErrorType<ProblemDetails>

    export const useCreateApiResourceRole = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApiResourceRole>>, TError,{id: string;data: CreateOrUpdateApiResourceRoleModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createApiResourceRole>>,
        TError,
        {id: string;data: CreateOrUpdateApiResourceRoleModel},
        TContext
      > => {

      const mutationOptions = getCreateApiResourceRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the basic information of an api resource role<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourceRoleById = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourceRoleByIdResponse>(
      {url: `/api-resources/roles/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiResourceRoleByIdQueryKey = (id: string,) => {
    return [`/api-resources/roles/${id}`] as const;
    }

    
export const getGetApiResourceRoleByIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceRoleById>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceRoleByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceRoleById>>> = ({ signal }) => getApiResourceRoleById(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceRoleByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceRoleById>>>
export type GetApiResourceRoleByIdQueryError = ErrorType<ProblemDetails>


export function useGetApiResourceRoleById<TData = Awaited<ReturnType<typeof getApiResourceRoleById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceRoleById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceRoleById<TData = Awaited<ReturnType<typeof getApiResourceRoleById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceRoleById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceRoleById<TData = Awaited<ReturnType<typeof getApiResourceRoleById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourceRoleById<TData = Awaited<ReturnType<typeof getApiResourceRoleById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourceRoleByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates an existing api resource role<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write
 */
export const updateApiResourceRole = (
    id: string,
    createOrUpdateApiResourceRoleModel: CreateOrUpdateApiResourceRoleModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetApiResourceRoleByIdResponse>(
      {url: `/api-resources/roles/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateApiResourceRoleModel
    },
      options);
    }
  


export const getUpdateApiResourceRoleMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateApiResourceRole>>, TError,{id: string;data: CreateOrUpdateApiResourceRoleModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateApiResourceRole>>, TError,{id: string;data: CreateOrUpdateApiResourceRoleModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateApiResourceRole>>, {id: string;data: CreateOrUpdateApiResourceRoleModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateApiResourceRole(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateApiResourceRoleMutationResult = NonNullable<Awaited<ReturnType<typeof updateApiResourceRole>>>
    export type UpdateApiResourceRoleMutationBody = CreateOrUpdateApiResourceRoleModel
    export type UpdateApiResourceRoleMutationError = ErrorType<ProblemDetails>

    export const useUpdateApiResourceRole = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateApiResourceRole>>, TError,{id: string;data: CreateOrUpdateApiResourceRoleModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateApiResourceRole>>,
        TError,
        {id: string;data: CreateOrUpdateApiResourceRoleModel},
        TContext
      > => {

      const mutationOptions = getUpdateApiResourceRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deletes an existing api Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write
 */
export const deleteApiResourceRole = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/api-resources/roles/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiResourceRoleMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiResourceRole>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiResourceRole>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiResourceRole>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiResourceRole(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiResourceRoleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiResourceRole>>>
    
    export type DeleteApiResourceRoleMutationError = ErrorType<ProblemDetails>

    export const useDeleteApiResourceRole = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiResourceRole>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiResourceRole>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiResourceRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the details of an api resource role<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourceRoleDetailsById = (
    id: string,
    params?: GetApiResourceRoleDetailsByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourceRoleDetailsByIdResponse>(
      {url: `/api-resources/roles/${id}/detail`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiResourceRoleDetailsByIdQueryKey = (id: string,
    params?: GetApiResourceRoleDetailsByIdParams,) => {
    return [`/api-resources/roles/${id}/detail`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiResourceRoleDetailsByIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetApiResourceRoleDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceRoleDetailsByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>> = ({ signal }) => getApiResourceRoleDetailsById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceRoleDetailsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>>
export type GetApiResourceRoleDetailsByIdQueryError = ErrorType<ProblemDetails>


export function useGetApiResourceRoleDetailsById<TData = Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetApiResourceRoleDetailsByIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceRoleDetailsById<TData = Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceRoleDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceRoleDetailsById<TData = Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceRoleDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourceRoleDetailsById<TData = Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceRoleDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourceRoleDetailsByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get all members of an api resource role<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourceRoleMembers = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourceRoleMembersResponse>(
      {url: `/api-resources/roles/${id}/members`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiResourceRoleMembersQueryKey = (id: string,) => {
    return [`/api-resources/roles/${id}/members`] as const;
    }

    
export const getGetApiResourceRoleMembersQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceRoleMembersQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceRoleMembers>>> = ({ signal }) => getApiResourceRoleMembers(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceRoleMembersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceRoleMembers>>>
export type GetApiResourceRoleMembersQueryError = ErrorType<ProblemDetails>


export function useGetApiResourceRoleMembers<TData = Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceRoleMembers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceRoleMembers<TData = Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceRoleMembers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceRoleMembers<TData = Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourceRoleMembers<TData = Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleMembers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourceRoleMembersQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get all API resource scopes for an api resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourceScopes = (
    id: string,
    params?: GetApiResourceScopesParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourceScopesByApiResourceIdResponse>(
      {url: `/api-resources/${id}/scopes`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiResourceScopesQueryKey = (id: string,
    params?: GetApiResourceScopesParams,) => {
    return [`/api-resources/${id}/scopes`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiResourceScopesQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceScopes>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetApiResourceScopesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopes>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceScopesQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceScopes>>> = ({ signal }) => getApiResourceScopes(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceScopesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceScopes>>>
export type GetApiResourceScopesQueryError = ErrorType<ProblemDetails>


export function useGetApiResourceScopes<TData = Awaited<ReturnType<typeof getApiResourceScopes>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetApiResourceScopesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopes>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceScopes>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceScopes<TData = Awaited<ReturnType<typeof getApiResourceScopes>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceScopesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopes>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceScopes>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceScopes<TData = Awaited<ReturnType<typeof getApiResourceScopes>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceScopesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopes>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourceScopes<TData = Awaited<ReturnType<typeof getApiResourceScopes>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourceScopesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopes>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourceScopesQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new api resource scope<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write
 */
export const createApiResourceScope = (
    id: string,
    createApiResourceScopeModel: CreateApiResourceScopeModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetApiResourceScopeByIdResponse>(
      {url: `/api-resources/${id}/scopes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createApiResourceScopeModel
    },
      options);
    }
  


export const getCreateApiResourceScopeMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApiResourceScope>>, TError,{id: string;data: CreateApiResourceScopeModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createApiResourceScope>>, TError,{id: string;data: CreateApiResourceScopeModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createApiResourceScope>>, {id: string;data: CreateApiResourceScopeModel}> = (props) => {
          const {id,data} = props ?? {};

          return  createApiResourceScope(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateApiResourceScopeMutationResult = NonNullable<Awaited<ReturnType<typeof createApiResourceScope>>>
    export type CreateApiResourceScopeMutationBody = CreateApiResourceScopeModel
    export type CreateApiResourceScopeMutationError = ErrorType<ProblemDetails>

    export const useCreateApiResourceScope = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApiResourceScope>>, TError,{id: string;data: CreateApiResourceScopeModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createApiResourceScope>>,
        TError,
        {id: string;data: CreateApiResourceScopeModel},
        TContext
      > => {

      const mutationOptions = getCreateApiResourceScopeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the basic information of an api resource scope<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourceScopeById = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourceScopeByIdResponse>(
      {url: `/api-resources/scopes/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiResourceScopeByIdQueryKey = (id: string,) => {
    return [`/api-resources/scopes/${id}`] as const;
    }

    
export const getGetApiResourceScopeByIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceScopeById>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceScopeByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceScopeById>>> = ({ signal }) => getApiResourceScopeById(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceScopeByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceScopeById>>>
export type GetApiResourceScopeByIdQueryError = ErrorType<ProblemDetails>


export function useGetApiResourceScopeById<TData = Awaited<ReturnType<typeof getApiResourceScopeById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceScopeById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceScopeById<TData = Awaited<ReturnType<typeof getApiResourceScopeById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceScopeById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceScopeById<TData = Awaited<ReturnType<typeof getApiResourceScopeById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourceScopeById<TData = Awaited<ReturnType<typeof getApiResourceScopeById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourceScopeByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates an existing api resource scope<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write
 */
export const updateApiResourceScope = (
    id: string,
    updateApiResourceScopeModel: UpdateApiResourceScopeModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetApiResourceScopeByIdResponse>(
      {url: `/api-resources/scopes/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateApiResourceScopeModel
    },
      options);
    }
  


export const getUpdateApiResourceScopeMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateApiResourceScope>>, TError,{id: string;data: UpdateApiResourceScopeModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateApiResourceScope>>, TError,{id: string;data: UpdateApiResourceScopeModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateApiResourceScope>>, {id: string;data: UpdateApiResourceScopeModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateApiResourceScope(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateApiResourceScopeMutationResult = NonNullable<Awaited<ReturnType<typeof updateApiResourceScope>>>
    export type UpdateApiResourceScopeMutationBody = UpdateApiResourceScopeModel
    export type UpdateApiResourceScopeMutationError = ErrorType<ProblemDetails>

    export const useUpdateApiResourceScope = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateApiResourceScope>>, TError,{id: string;data: UpdateApiResourceScopeModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateApiResourceScope>>,
        TError,
        {id: string;data: UpdateApiResourceScopeModel},
        TContext
      > => {

      const mutationOptions = getUpdateApiResourceScopeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deletes an existing API Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write
 */
export const deleteApiResourceScope = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/api-resources/scopes/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiResourceScopeMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiResourceScope>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiResourceScope>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiResourceScope>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiResourceScope(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiResourceScopeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiResourceScope>>>
    
    export type DeleteApiResourceScopeMutationError = ErrorType<ProblemDetails>

    export const useDeleteApiResourceScope = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiResourceScope>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiResourceScope>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiResourceScopeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get all members of an api resource scope<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourceScopeClients = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourceScopeClientsResponse>(
      {url: `/api-resources/scopes/${id}/clients`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiResourceScopeClientsQueryKey = (id: string,) => {
    return [`/api-resources/scopes/${id}/clients`] as const;
    }

    
export const getGetApiResourceScopeClientsQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceScopeClientsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceScopeClients>>> = ({ signal }) => getApiResourceScopeClients(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceScopeClientsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceScopeClients>>>
export type GetApiResourceScopeClientsQueryError = ErrorType<ProblemDetails>


export function useGetApiResourceScopeClients<TData = Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceScopeClients>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceScopeClients<TData = Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourceScopeClients>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourceScopeClients<TData = Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourceScopeClients<TData = Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceScopeClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourceScopeClientsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Adds a token exchange client to the API Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write<br>login.umdaschgroup.com/clients.read
 */
export const addTokenExchangeClient = (
    id: string,
    clientid: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/api-resources/${id}/token-exchange/${clientid}`, method: 'PUT'
    },
      options);
    }
  


export const getAddTokenExchangeClientMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTokenExchangeClient>>, TError,{id: string;clientid: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof addTokenExchangeClient>>, TError,{id: string;clientid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addTokenExchangeClient>>, {id: string;clientid: string}> = (props) => {
          const {id,clientid} = props ?? {};

          return  addTokenExchangeClient(id,clientid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddTokenExchangeClientMutationResult = NonNullable<Awaited<ReturnType<typeof addTokenExchangeClient>>>
    
    export type AddTokenExchangeClientMutationError = ErrorType<ProblemDetails>

    export const useAddTokenExchangeClient = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTokenExchangeClient>>, TError,{id: string;clientid: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof addTokenExchangeClient>>,
        TError,
        {id: string;clientid: string},
        TContext
      > => {

      const mutationOptions = getAddTokenExchangeClientMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Removes a token exchange client from the API Resource<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.write<br>login.umdaschgroup.com/clients.read
 */
export const removeTokenExchangeClient = (
    id: string,
    clientid: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/api-resources/${id}/token-exchange/${clientid}`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveTokenExchangeClientMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeTokenExchangeClient>>, TError,{id: string;clientid: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof removeTokenExchangeClient>>, TError,{id: string;clientid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeTokenExchangeClient>>, {id: string;clientid: string}> = (props) => {
          const {id,clientid} = props ?? {};

          return  removeTokenExchangeClient(id,clientid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveTokenExchangeClientMutationResult = NonNullable<Awaited<ReturnType<typeof removeTokenExchangeClient>>>
    
    export type RemoveTokenExchangeClientMutationError = ErrorType<ProblemDetails>

    export const useRemoveTokenExchangeClient = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeTokenExchangeClient>>, TError,{id: string;clientid: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof removeTokenExchangeClient>>,
        TError,
        {id: string;clientid: string},
        TContext
      > => {

      const mutationOptions = getRemoveTokenExchangeClientMutationOptions(options);

      return useMutation(mutationOptions);
    }
    