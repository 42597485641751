/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateOrUpdateApplicationModel,
  CreateOrUpdateClientModel,
  GetApiResourcesByApplicationIdParams,
  GetApiResourcesByApplicationIdResponse,
  GetApplicationByIdParams,
  GetApplicationByIdResponse,
  GetApplicationDetailsByIdParams,
  GetApplicationDetailsByIdResponse,
  GetApplicationsParams,
  GetApplicationsResponse,
  GetClientDetailsByIdResponse,
  GetClientsByApplicationIdParams,
  GetClientsByApplicationIdResponse,
  ProblemDetails,
  UploadApplicationImageBody
} from '.././models'
import { customMutator } from '../../customMutator-v3';
import type { ErrorType } from '../../customMutator-v3';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns the basic information of a application<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/applications.read
 */
export const getApplicationById = (
    id: string,
    params?: GetApplicationByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApplicationByIdResponse>(
      {url: `/applications/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApplicationByIdQueryKey = (id: string,
    params?: GetApplicationByIdParams,) => {
    return [`/applications/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApplicationByIdQueryOptions = <TData = Awaited<ReturnType<typeof getApplicationById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetApplicationByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApplicationByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApplicationById>>> = ({ signal }) => getApplicationById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApplicationById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApplicationByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApplicationById>>>
export type GetApplicationByIdQueryError = ErrorType<ProblemDetails>


export function useGetApplicationById<TData = Awaited<ReturnType<typeof getApplicationById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetApplicationByIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApplicationById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApplicationById<TData = Awaited<ReturnType<typeof getApplicationById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApplicationByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApplicationById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApplicationById<TData = Awaited<ReturnType<typeof getApplicationById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApplicationByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApplicationById<TData = Awaited<ReturnType<typeof getApplicationById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApplicationByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApplicationByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates an existing application<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/applications.write
 */
export const updateApplication = (
    id: string,
    createOrUpdateApplicationModel: CreateOrUpdateApplicationModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetApplicationByIdResponse>(
      {url: `/applications/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateApplicationModel
    },
      options);
    }
  


export const getUpdateApplicationMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateApplication>>, TError,{id: string;data: CreateOrUpdateApplicationModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateApplication>>, TError,{id: string;data: CreateOrUpdateApplicationModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateApplication>>, {id: string;data: CreateOrUpdateApplicationModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateApplication(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateApplicationMutationResult = NonNullable<Awaited<ReturnType<typeof updateApplication>>>
    export type UpdateApplicationMutationBody = CreateOrUpdateApplicationModel
    export type UpdateApplicationMutationError = ErrorType<ProblemDetails>

    export const useUpdateApplication = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateApplication>>, TError,{id: string;data: CreateOrUpdateApplicationModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateApplication>>,
        TError,
        {id: string;data: CreateOrUpdateApplicationModel},
        TContext
      > => {

      const mutationOptions = getUpdateApplicationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deletes an existing application<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/applications.write
 */
export const deleteApplication = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/applications/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApplicationMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApplication>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApplication>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApplication>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteApplication(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApplicationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApplication>>>
    
    export type DeleteApplicationMutationError = ErrorType<ProblemDetails>

    export const useDeleteApplication = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApplication>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApplication>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteApplicationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the details of a application<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/applications.read
 */
export const getApplicationDetailsById = (
    id: string,
    params?: GetApplicationDetailsByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApplicationDetailsByIdResponse>(
      {url: `/applications/${id}/detail`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApplicationDetailsByIdQueryKey = (id: string,
    params?: GetApplicationDetailsByIdParams,) => {
    return [`/applications/${id}/detail`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApplicationDetailsByIdQueryOptions = <TData = Awaited<ReturnType<typeof getApplicationDetailsById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetApplicationDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApplicationDetailsByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApplicationDetailsById>>> = ({ signal }) => getApplicationDetailsById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApplicationDetailsById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApplicationDetailsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApplicationDetailsById>>>
export type GetApplicationDetailsByIdQueryError = ErrorType<ProblemDetails>


export function useGetApplicationDetailsById<TData = Awaited<ReturnType<typeof getApplicationDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetApplicationDetailsByIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationDetailsById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApplicationDetailsById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApplicationDetailsById<TData = Awaited<ReturnType<typeof getApplicationDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApplicationDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationDetailsById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApplicationDetailsById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApplicationDetailsById<TData = Awaited<ReturnType<typeof getApplicationDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApplicationDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApplicationDetailsById<TData = Awaited<ReturnType<typeof getApplicationDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApplicationDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplicationDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApplicationDetailsByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a list of applications<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/applications.read
 */
export const getApplications = (
    params?: GetApplicationsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApplicationsResponse>(
      {url: `/applications`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApplicationsQueryKey = (params?: GetApplicationsParams,) => {
    return [`/applications`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApplicationsQueryOptions = <TData = Awaited<ReturnType<typeof getApplications>>, TError = ErrorType<ProblemDetails>>(params?: GetApplicationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplications>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApplicationsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApplications>>> = ({ signal }) => getApplications(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApplications>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApplicationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApplications>>>
export type GetApplicationsQueryError = ErrorType<ProblemDetails>


export function useGetApplications<TData = Awaited<ReturnType<typeof getApplications>>, TError = ErrorType<ProblemDetails>>(
 params: undefined |  GetApplicationsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplications>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApplications>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApplications<TData = Awaited<ReturnType<typeof getApplications>>, TError = ErrorType<ProblemDetails>>(
 params?: GetApplicationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplications>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApplications>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApplications<TData = Awaited<ReturnType<typeof getApplications>>, TError = ErrorType<ProblemDetails>>(
 params?: GetApplicationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplications>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApplications<TData = Awaited<ReturnType<typeof getApplications>>, TError = ErrorType<ProblemDetails>>(
 params?: GetApplicationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApplications>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApplicationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new application<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/applications.write
 */
export const createApplication = (
    createOrUpdateApplicationModel: CreateOrUpdateApplicationModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetApplicationByIdResponse>(
      {url: `/applications`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateApplicationModel
    },
      options);
    }
  


export const getCreateApplicationMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApplication>>, TError,{data: CreateOrUpdateApplicationModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createApplication>>, TError,{data: CreateOrUpdateApplicationModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createApplication>>, {data: CreateOrUpdateApplicationModel}> = (props) => {
          const {data} = props ?? {};

          return  createApplication(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateApplicationMutationResult = NonNullable<Awaited<ReturnType<typeof createApplication>>>
    export type CreateApplicationMutationBody = CreateOrUpdateApplicationModel
    export type CreateApplicationMutationError = ErrorType<ProblemDetails>

    export const useCreateApplication = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApplication>>, TError,{data: CreateOrUpdateApplicationModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createApplication>>,
        TError,
        {data: CreateOrUpdateApplicationModel},
        TContext
      > => {

      const mutationOptions = getCreateApplicationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Uploads an application image for an existing application<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/applications.write
 */
export const uploadApplicationImage = (
    id: string,
    uploadApplicationImageBody: UploadApplicationImageBody,
 options?: SecondParameter<typeof customMutator>,) => {
      
      const formData = new FormData();
formData.append('File', uploadApplicationImageBody.File)

      return customMutator<void>(
      {url: `/applications/${id}/image`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getUploadApplicationImageMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadApplicationImage>>, TError,{id: string;data: UploadApplicationImageBody}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadApplicationImage>>, TError,{id: string;data: UploadApplicationImageBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadApplicationImage>>, {id: string;data: UploadApplicationImageBody}> = (props) => {
          const {id,data} = props ?? {};

          return  uploadApplicationImage(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadApplicationImageMutationResult = NonNullable<Awaited<ReturnType<typeof uploadApplicationImage>>>
    export type UploadApplicationImageMutationBody = UploadApplicationImageBody
    export type UploadApplicationImageMutationError = ErrorType<ProblemDetails>

    export const useUploadApplicationImage = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadApplicationImage>>, TError,{id: string;data: UploadApplicationImageBody}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof uploadApplicationImage>>,
        TError,
        {id: string;data: UploadApplicationImageBody},
        TContext
      > => {

      const mutationOptions = getUploadApplicationImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Requests the creation of a new client<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.write
 */
export const createClient = (
    id: string,
    createOrUpdateClientModel: CreateOrUpdateClientModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetClientDetailsByIdResponse>(
      {url: `/applications/${id}/clients`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateClientModel
    },
      options);
    }
  


export const getCreateClientMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createClient>>, TError,{id: string;data: CreateOrUpdateClientModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createClient>>, TError,{id: string;data: CreateOrUpdateClientModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createClient>>, {id: string;data: CreateOrUpdateClientModel}> = (props) => {
          const {id,data} = props ?? {};

          return  createClient(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateClientMutationResult = NonNullable<Awaited<ReturnType<typeof createClient>>>
    export type CreateClientMutationBody = CreateOrUpdateClientModel
    export type CreateClientMutationError = ErrorType<ProblemDetails>

    export const useCreateClient = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createClient>>, TError,{id: string;data: CreateOrUpdateClientModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createClient>>,
        TError,
        {id: string;data: CreateOrUpdateClientModel},
        TContext
      > => {

      const mutationOptions = getCreateClientMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get Clients by application Id<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.read
 */
export const getClientsByApplicationId = (
    id: string,
    params?: GetClientsByApplicationIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetClientsByApplicationIdResponse>(
      {url: `/applications/${id}/clients`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetClientsByApplicationIdQueryKey = (id: string,
    params?: GetClientsByApplicationIdParams,) => {
    return [`/applications/${id}/clients`, ...(params ? [params]: [])] as const;
    }

    
export const getGetClientsByApplicationIdQueryOptions = <TData = Awaited<ReturnType<typeof getClientsByApplicationId>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetClientsByApplicationIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientsByApplicationId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClientsByApplicationIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientsByApplicationId>>> = ({ signal }) => getClientsByApplicationId(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getClientsByApplicationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetClientsByApplicationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getClientsByApplicationId>>>
export type GetClientsByApplicationIdQueryError = ErrorType<ProblemDetails>


export function useGetClientsByApplicationId<TData = Awaited<ReturnType<typeof getClientsByApplicationId>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetClientsByApplicationIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientsByApplicationId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getClientsByApplicationId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetClientsByApplicationId<TData = Awaited<ReturnType<typeof getClientsByApplicationId>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetClientsByApplicationIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientsByApplicationId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getClientsByApplicationId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetClientsByApplicationId<TData = Awaited<ReturnType<typeof getClientsByApplicationId>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetClientsByApplicationIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientsByApplicationId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetClientsByApplicationId<TData = Awaited<ReturnType<typeof getClientsByApplicationId>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetClientsByApplicationIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientsByApplicationId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetClientsByApplicationIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get API Resources by application Id<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/apiresources.read
 */
export const getApiResourcesByApplicationId = (
    id: string,
    params?: GetApiResourcesByApplicationIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetApiResourcesByApplicationIdResponse>(
      {url: `/applications/${id}/api-resources`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiResourcesByApplicationIdQueryKey = (id: string,
    params?: GetApiResourcesByApplicationIdParams,) => {
    return [`/applications/${id}/api-resources`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiResourcesByApplicationIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetApiResourcesByApplicationIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourcesByApplicationIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourcesByApplicationId>>> = ({ signal }) => getApiResourcesByApplicationId(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourcesByApplicationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourcesByApplicationId>>>
export type GetApiResourcesByApplicationIdQueryError = ErrorType<ProblemDetails>


export function useGetApiResourcesByApplicationId<TData = Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetApiResourcesByApplicationIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourcesByApplicationId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourcesByApplicationId<TData = Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourcesByApplicationIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiResourcesByApplicationId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiResourcesByApplicationId<TData = Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourcesByApplicationIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiResourcesByApplicationId<TData = Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetApiResourcesByApplicationIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourcesByApplicationId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiResourcesByApplicationIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



