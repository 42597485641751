import { Notifications } from '@mantine/notifications';
import { QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider, MantineOverrideProvider, ThemeProvider } from '@uag/react-core';
import { BrowserRouter } from 'react-router-dom';

import { configurationIdentityServer } from 'app/configurationIdentityServer';
import { DefaultErrorBoundary } from 'shared/components';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';
import { AppInsightsContextProvider } from 'utils/ApplicationInsightsContextProvider';
import { AppRoutes } from './AppRoutes';
import { defaultQueryClient } from './queryDefaults';
import './App.css';
import '../i18n';
import './MantineStyles.css';
import '@mantine/core/styles/Tooltip.css';
import { getCustomeThemeOverrides } from './themeOverrides';

export const App = () => {
    return (
        <BrowserRouter>
            <ThemeProvider>
                <MantineOverrideProvider customThemeOverrides={getCustomeThemeOverrides()}>
                    <QueryClientProvider client={defaultQueryClient}>
                        <AuthProvider
                            authority={import.meta.env.VITE_APP_OIDC_AUTHORITY}
                            configuration={configurationIdentityServer}
                        >
                            <AppInsightsContextProvider>
                                <Notifications />
                                <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                                    <AppRoutes />
                                </DefaultErrorBoundary>
                            </AppInsightsContextProvider>
                        </AuthProvider>
                    </QueryClientProvider>
                </MantineOverrideProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
};
