/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type ClientAccessibility = typeof ClientAccessibility[keyof typeof ClientAccessibility];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientAccessibility = {
  Dedicated: 'Dedicated',
  UmdaschGroup: 'UmdaschGroup',
  Public: 'Public',
} as const;
