/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type AssignmentPrincipalType = typeof AssignmentPrincipalType[keyof typeof AssignmentPrincipalType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentPrincipalType = {
  UserId: 'UserId',
  ClientId: 'ClientId',
  GroupId: 'GroupId',
  UserEmail: 'UserEmail',
  TenantId: 'TenantId',
} as const;
