import { Group } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Select } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSearchClients } from 'api/v3/clients/clients';
import { BasicClientModel } from 'api/v3/models';

type Props = {
    index: number;
    propertyBasePath: string;
    tokenExchangeClientsOnly?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturnType<any, any>;
    requiredPermission?: string;
    onClientSelected?: (group: BasicClientModel) => void;
};

export const ClientSelector = ({ index, form, propertyBasePath, onClientSelected, tokenExchangeClientsOnly }: Props) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState<string>();

    const { data: clients, isLoading } = useSearchClients({ searchTerm: search, tokenExchange: tokenExchangeClientsOnly }, {});

    const handleClientSelected = (value: string | null) => {
        if (value) {
            const foundClient = clients?.data.find((client) => client.data.id === value);
            if (foundClient) {
                onClientSelected && onClientSelected(foundClient.data);
            }
        }
    };

    const clientOptions = clients
        ? clients.data.map((group) => {
              return {
                  value: group.data.id,
                  label: group.data.name
              };
          })
        : [];

    return (
        <Group grow>
            <Select
                data={clientOptions}
                error={form.errors[`${propertyBasePath}.${index}.name`]}
                nothingFoundMessage={search ? (isLoading ? t('fetchingClients') : t('noClientsFound')) : t('enterSearchString')}
                placeholder={t('selectItem', { itemType: t('client') })}
                searchValue={search}
                clearable
                searchable
                onChange={handleClientSelected}
                onSearchChange={setSearch}
            />
        </Group>
    );
};
